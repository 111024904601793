import React, { Component } from "react"
import { navigate } from "gatsby"
import { isLoggedIn } from "../../services/auth"
const PrivateRoute = ({ component: Component, location, ...rest }) => {
  console.log('checking route')
  if (!isLoggedIn() && location.pathname !== `/hcp/procedure-video/`) {
    console.log('not logged in')
    navigate("/hcp/procedure-video/")
    return null
  }
  return <Component {...rest} />
}
export default PrivateRoute