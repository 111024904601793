import React, { useEffect, useState } from "react";
import "./card-videoModal.scss";
import Modal from 'react-awesome-modal';
import WistiaVideo from "../wistia-video";

function CardVideoModal({
    wistiaID,
    thumbnail,
    className = "",
    ariaLabel,
}) {

    const [open, setOpen] = useState(false);
    const [autoPlay, setAutoPlay] = useState(false)

    const handleClose = () => {
        setOpen(false);
        setAutoPlay(false);

    };
    const handleOpen = () => {
        setOpen(true);
        setAutoPlay(true);
    };

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                const videoPlayButton = document.getElementById(wistiaID);
                videoPlayButton.addEventListener('click', function (e) {
                    e.preventDefault()
                })
                videoPlayButton.click();
            }, 100)
        }
    }, [open]);

    return (
        <>
            <div
                className={`video-thumb-wrapper ${className}`}
                onClick={handleOpen}
                tabIndex={0}
                role="button"
                aria-label={ariaLabel}
                style={{
                    backgroundImage: `url(${(thumbnail) ?? thumbnail
                        })`,
                }}
            >
                <div className="btn-play"></div>

            </div>

            <Modal visible={open} onClose={handleClose} onClickAway={handleClose}>
                <div className="video-modal__wrapper">
                    <div className="video-modal-close" onClick={handleClose}>
                        <span icon="cross" aria-hidden="true" className="bp3-icon bp3-icon-cross">
                            <svg data-icon="cross" width="25" height="25" viewBox="0 0 20 20">
                                <path d="M11.41 10l4.29-4.29c.19-.18.3-.43.3-.71a1.003 1.003 0 00-1.71-.71L10 8.59l-4.29-4.3a1.003 1.003 0 00-1.42 1.42L8.59 10 4.3 14.29c-.19.18-.3.43-.3.71a1.003 1.003 0 001.71.71l4.29-4.3 4.29 4.29c.18.19.43.3.71.3a1.003 1.003 0 00.71-1.71L11.41 10z" fill-rule="evenodd" ></path>
                            </svg>
                        </span>
                    </div>
                    <div className="video-modal-content">
                        <a id={wistiaID} href={`#wistia_${wistiaID}`}></a>
                        {open && <WistiaVideo wistiaID={wistiaID} />}
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default CardVideoModal;
