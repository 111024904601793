import React from "react"
import { Router } from "@reach/router"
import Layout from '../../../components/dfa-theme/layout/hcp'
import ProcedurePage from "../../../components/hcp-procdure-video-page"
import PrivateRoute from "../../../components/private-route"
import Login from "../../../components/login"

const App = () => (
  <Layout>
    <Router basepath="/hcp/procedure-video">
      <PrivateRoute path="/view" component={ProcedurePage} />
      <Login path="/" />
    </Router>
  </Layout>
)
export default App