import React, { Fragment } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import CardVideoModal from '../card-videoModal';
import LAProcedureVideoThumbnail from '../../images/procedure-video/supprelin-la-procedure-video-thumbnail.png'
import RemovalProcedureVideoThumbnail from '../../images/procedure-video/supprelin-removal-procedure-video-thumbnail.webp'


const metaTags = {
  description: 'Access videos for SUPPRELIN® LA (histrelin acetate) subcutaneous implant.',
  keywords: 'Homepage',
  title: 'Video Library | SUPPRELIN® LA (histrelin acetate)'
}

export default function HCPProcedureVideoPage() {

  return (
    <Fragment>

      <div className="block-procedure-video">
        <Row>
          <Col lg={6}>
            <h1>SUPPRELIN<sup>&reg;</sup> LA<br /> Procedure Video</h1>
            {/* <WistiaVideo wistiaID="y08pmoo239" /> */}
            <CardVideoModal
              thumbnail={LAProcedureVideoThumbnail}
              wistiaID='y08pmoo239'
            />
          </Col>
          <Col lg={6}>
            <h1>SUPPRELIN<sup>&reg;</sup> LA Removal Procedure Best Practices Video</h1>
            <CardVideoModal
              thumbnail={RemovalProcedureVideoThumbnail}
              wistiaID='8zwkzlkrom'
            />
          </Col>
        </Row>
      </div>
    </Fragment>
  )

}