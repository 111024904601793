import axios from 'axios';
import { navigate } from "gatsby"

const DFA_API_AUTH_URL = process.env.DFA_API_AUTH_URL;

export const isBrowser = () => typeof window !== "undefined"
export const getUser = () =>
  isBrowser() && window.sessionStorage.getItem("gatsbyUser")
    ? JSON.parse(window.sessionStorage.getItem("gatsbyUser"))
    : {}
const setUser = user =>
  window.sessionStorage.setItem("gatsbyUser", JSON.stringify(user))

export const handleLogin = ({ username, password }) => {
  
  const dataToSend = {
      data: {
        username: username,
        password: password
      }
  }

  return axios
            .post(DFA_API_AUTH_URL, JSON.stringify(dataToSend))
            .then( response => {
                console.log('have response')
                console.log(response.status);

                // response code test
                if (response.status == 200 ) {
                    console.log('valid response')
                    return setUser({
                    username: `HCP`,
                    name: ``,
                    email: ``,
                    })
                }
                return false
            })
            .catch(error => {
                console.log(error);
                alert('Error: password invalid');
            })

}
export const isLoggedIn = () => {   
  const user = getUser()
  return !!user.username
}
export const logout = callback => {
  setUser({})
  callback()
}